import Router from 'vue-router';
import Vue from 'vue';
Vue.use(Router)
const vueRouter = new Router({
  base: process.env.VUE_APP_PATH,
  mode: 'hash',
  routes: [{
    path: '/',
    meta: {
      requireAuth: true  // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () => import( /* webpackChunkName: "page" */ '@/page/index'),
    children: [
        {
      path: '',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list'),
    },
    {
      path: 'category',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/category'),
    }, {
      path: 'db',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/db'),
    }, {
      path: 'map',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/map'),
    }, {
      path: 'document',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/document'),
    }, {
      path: 'components',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/components'),
    }, {
      path: 'record',
      component: () => import( /* webpackChunkName: "page" */ '@/page/list/record'),
    }]
  }, {
    path: '/create',
    name: 'create',
    meta: {
      requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/create'),
  }, {
    path: '/build/:id',
    name: 'build',
    meta: {
      requireAuth: true,  // 添加该字段，表示进入这个路由是需要登录的
    },
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/build'),
  }, {
    path: '/view/:id',
    name: 'view',
    component: () =>
      import( /* webpackChunkName: "page" */ '@/page/view')
  }, {
    path: '/login',
    name: 'login',
    component: () =>
        import( /* webpackChunkName: "page" */ '@/page/login')
  }, {
    path: '/edit/exportData',
    name: 'exportData',
    component: () => import( /* webpackChunkName: "page" */ '@/page/exportData'),
    meta:{ requiresAuth : true}
  },]
})

vueRouter.beforeEach((to, from, next) => {
//如果去往登录页则放行
  //console.log(to.path)
  if (to.name === 'login') {
    next();
  } else if(to.name === 'view') {
    next();
  }  else if(to.name === 'exportData') {
    next();
  } else {
    // 从本地存储里获取token
    let token = localStorage.getItem('token');
    // 判断token是否为空如果为空则跳转到登录页 如果有则放行
    console.log(token)
    if (token === null || token === '') {
      next({path:'/login'});
    } else if(token === 'export') {
      next({path:'/edit/exportData'})
    } else if(token === 'admin'){
      next();
    }
  }
});
export default vueRouter;
